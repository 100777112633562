<template>
  <div class="camera-history-modal">
    <vxe-modal
      v-model="cameraHistoryVisible"
      width="70%"
      height="100%"
      class="history-modal"
      :title="modalTitle"
      @close="handleCloaseCameraHistory"
      @hide="handleCloaseCameraHistory"
      show-zoom
      resize
      :lock-view="false"
      :mask="false"
      :esc-closable="true"
      :position="position"
      transfer
    >
      <template #default>
        <a-row style="height: 45%">
          <a-col :span="24" style="height: 100%">
            <revo-video-player
              :url="vedioType === '1' ? videoUrlObj[mainCameras.streamId] : brtcUrl"
              @handleReady="handleReady($event, mainCameras.streamId)"
              @handleError="handleError(mainCameras.streamId)"
              :is-live="false"
              :progressMarkers="progressMarkers[mainCameras.streamId]"
              :seekTime="source === 'eventVideo' && seekTime[mainCameras.streamId]"
            />
            <p v-if="source === 'eventVideo'">播放查询出来的第一个视频，该视频将从事件发生的前20秒开始播放</p>
            <p v-else>(提供7天内的历史视频查询)</p>
          </a-col>
        </a-row>
        <a-row style="height: auto">
          <a-col :span="24" class="table-page-search-wrapper">
            <a-divider />
            <a-form layout="inline">
              <a-row :gutter="48">
                <!-- 选择视频类型 -->
                <a-col v-if="source !== 'eventVideo'" :span="16 ">
                  <a-form-item label="类型" prop="vedioType">
                    <a-radio-group button-style="solid" v-model="vedioType" @change="vedioTypeChange">
                      <a-radio-button :key="0" value="1">
                        实时监控
                      </a-radio-button>
                      <a-radio-button :key="1" value="2">
                        遥控
                      </a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <!-- 选择摄像头类型 -->
                <a-col v-if="vedioType === '1'" :span="source === 'eventVideo' ? 16 : 24">
                  <a-form-item label="选择摄像头" prop="code">
                    <a-radio-group button-style="solid" v-model="queryParam.streamId" @change="radioChange">
                      <a-radio-button v-for="(c, index) in cameras" :key="index" :value="c.streamId">
                        {{ c.cameraName }}
                      </a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col :span="16" v-if="source !== 'eventVideo'">
                  <a-form-item label="时间范围">
                    <a-range-picker
                      style="width: 100%"
                      @change="rangPickerChange"
                      v-model="dateRange"
                      :ranges="{ 今天: [moment(), moment()], 这个月: [moment(), moment().endOf('month')] }"
                      show-time
                      format="YYYY-MM-DDTHH:mm:ss"
                      valueFormat="YYYY-MM-DDTHH:mm:ssZ"
                      allow-clear
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <span class="table-page-search-submitButtons">
                    <a-button
                      type="primary"
                      :style="source !== 'eventVideo' ? '' : 'float: right'"
                      @click="searchBtn()"
                      id="tableQueryBtn"
                    >
                      <a-icon type="search" />查询
                    </a-button>
                    <a-button v-if="source !== 'eventVideo'" style="margin-left: 8px" @click="resetQuery">
                      <a-icon type="redo" />重置
                    </a-button>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </a-col>
        </a-row>
        <a-row style="height: auto">
          <a-col :span="24">
            <!-- 数据展示 -->
            <a-table
              class="video-table"
              :loading="loading"
              :size="tableSize"
              :columns="columns"
              :data-source="historyObj[curStreamId]"
              :pagination="false"
              :rowClassName="rowClassName"
            >
              <span slot="width-height" slot-scope="text, record"> {{ record.width }} * {{ record.height }} </span>
              <span slot="operation" slot-scope="text, record, index">
                <a
                  :style="
                    playBtnDisabled[curStreamId] || unfreezingList[record.id]
                      ? 'color: #888 !important;cursor: not-allowed'
                      : ''
                  "
                  @click="!playBtnDisabled[curStreamId] && !unfreezingList[record.id] && playVideo(record, index)"
                >
                  {{
                    record.isFreeze
                      ? unfreezingList[record.id]
                        ? '解冻中'
                        : '解冻&播放'
                      : record.playing
                        ? '播放中'
                        : '播放'
                  }}
                </a>
              </span>
            </a-table>
            <!-- 分页 -->
            <a-pagination
              v-if="source !== 'eventVideo'"
              class="ant-table-pagination"
              show-size-changer
              show-quick-jumper
              :current="queryParam.pageObj[curStreamId] ? queryParam.pageObj[curStreamId].pageNum : queryParam.pageNum"
              :total="totalObj[curStreamId]"
              :page-size="queryParam.pageSize"
              :showTotal="(total) => `共 ${total} 条`"
              @showSizeChange="onShowSizeChange"
              @change="changeSize"
            />
          </a-col>
        </a-row>
      </template>
    </vxe-modal>
    <revo-video-modal
      v-for="(cam, index) in secondaryCameras"
      :is-live="false"
      :key="index"
      :progressMarkers="progressMarkers[cam.streamId || cam.iotId]"
      :seekTime="source === 'eventVideo' && seekTime[cam.streamId || cam.iotId]"
      :cameraInfo="cam"
      :videoUrl="videoUrlObj[cam.streamId]"
      :cameraId="cam.id"
      @closeModalCamera="closeModalCameraChild(cam.id, cam.streamId || cam.iotId)"
      @handleReady="handleReady($event, cam.streamId || cam.iotId)"
      @handleError="handleError(cam.streamId || cam.iotId)"
      :position="setCameraHistoryPosition(index)"
    />
  </div>
</template>

<script>
import get from 'lodash/get'
// import { handleModeData } from '@/translatedata/systemData.js'
import allIcons from '@/core/icons'
import RevoVideoPlayer from './RevoVideoPlayer'
import api, { getVideoHistory } from '@/api/iot/vehicle'
import { videoHistoryQuery, searchEventVideoList, unfreeze, checkFileType, videoBrtcHistoryQuery } from '@/api/iot/vehicleAbilityCamera'
// import { setVideo } from '@/api/device/carcontrol'
import RevoVideoModal from './RevoVideoModal'
import moment from 'moment'
// import DeptTreeVue from '@/views/iot/primitive/modules/DeptTree.vue'
import MonitorCameraBase from './MonitorCameraBase'

export default {
  name: 'MonitorCameraHistoryModal',
  mixins: [MonitorCameraBase],
  props: {
    info: {
      type: Object,
      default: () => {}
    },
    position: {
      type: Object,
      default: () => {}
    },
    cameraHistoryVisible: {
      type: Boolean,
      default: false
    },
    defaultDateRange: {
      type: Array,
      default: undefined
    },
    source: {
      type: String,
      default: ''
    }
  },
  components: {
    RevoVideoPlayer,
    RevoVideoModal
  },
  data() {
    return {
      api,
      cameraIcon: allIcons.cameraIcon,
      // cameraHistoryVisible: false,
      timer: null,
      videoUrl: null,
      url: api.videoUrlList[0],
      loading: false,
      btnType: '',
      total: 0,
      totalObj: {},
      dateRange: [],
      vedioType: '1',
      queryParam: {
        streamId: null,
        startTime: null,
        endTime: null,
        cameraType: undefined,
        vehicleInnerId: this.info.innerId,
        pageNum: 1,
        pageSize: 10,
        pageObj: {}
      },
      historyList: [],
      historyObj: {},
      playBtnDisabled: {},
      columns: [
        {
          title: '记录开始时间',
          dataIndex: 'startTime',
          key: 'startTime'
        },
        {
          title: '记录结束时间',
          dataIndex: 'endTime',
          key: 'endTime'
        },
        {
          title: '文件格式',
          dataIndex: 'fileFormat',
          key: 'fileFormat'
        },
        {
          title: '时长',
          dataIndex: 'allTime'
        },
        {
          title: '宽高',
          key: 'width-height',
          dataIndex: 'width-height',
          scopedSlots: { customRender: 'width-height' }
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      eventStartTime: '',
      showVedioTip: false,
      progressMarkers: {},
      seekTime: {},
      videoUrlObj: {},
      curStreamId: '',
      cameras: [],
      lastUrls: {},
      playingRowMap: {},
      unfreezing: false,
      unfreezingList: {},
      cameraTypeList: [],
      freezeTimeOut: {},
      brtcUrl: ''
    }
  },
  computed: {
    vehicleId() {
      return get(this.info, 'vehicleId', '')
    },
    // cameras() {
    //   const list = this.info ? this.info.cameras : []
    //   const newList = JSON.parse(JSON.stringify(list))
    //   if (this.cameras && list.length > 0) {
    //     for (const item of newList) {
    //       for (const tItem of this.cameras) {
    //         if (item.streamId === tItem.streamId) {
    //           item.cameraVisible = tItem.cameraVisible
    //         }
    //       }
    //     }
    //   }
    //   return list.filter(o => o.streamId)
    // },
    vehicleName() {
      if (!this.info) {
        return null
      }
      return this.info.name
    },
    modalTitle() {
      return this.info.vehicleId + '-历史视频-' + (this.vehicleName ? this.vehicleName : '')
    },
    showVedioUrl() {
      if (this.videoUrl.indexOf('http') === 0) {
        return this.videoUrl
      } else {
        return this.url + this.videoUrl
      }
    },
    mainCameras() {
      const arr = this.cameras.length ? this.cameras.filter((c) => c.mainCamera === 'yes') : []
      console.log('mainCameras', arr)
      if (!this.queryParam.streamId && arr[0]) {
        this.$set(this.queryParam, 'streamId', arr[0].streamId)
        this.searchBtn()
      }
      return arr && arr.length > 0 ? arr[0] : {}
    }
  },
  watch: {
    'info.vehicleId'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.handleCloaseCameraHistory()
      }
    },
    info(newVal, oldVal) {
      console.log('watch_info', newVal)
      if (newVal) {
        this.setCameras(newVal)
      }
    }
  },
  created() {
    // this.getCamearType()
    this.setDefaultRanbgeTime()
    console.log('this.info.rtc', this.info.rtc)
    if (this.info.rtc) {
      this.getVideoHistory()
    }
  },
  mounted() {
    // this.getFlv()
    this.setCameras(this.info, 'init')
  },
  destroyed() {},
  methods: {
    moment,
    getCamearType() {
      this.getDicts('iot_cameraDevice_type').then((response) => {
        this.cameraTypeList = response.data
      })
    },
    setCameras(data, type) {
      if (data.rtc) {
        return
      }
      const list = data.cameras || []
      const cameras = list.filter((o) => o.streamId || o.iotId)
      if (this.cameras.length > 0 && list.length > 0) {
        for (const item of cameras) {
          for (const tItem of this.cameras) {
            if ((item.streamId && item.streamId === tItem.streamId) || (item.iotId && item.iotId === tItem.iotId)) {
              this.$set(item, 'cameraVisible', type === 'init' ? false : tItem.cameraVisible)
            }
          }
        }
      }
      console.log('setCameras', cameras)
      this.cameras = cameras
    },
    setDefaultRanbgeTime() {
      if (this.defaultDateRange) {
        console.log('this.info', this.info)
        this.dateRange = this.defaultDateRange
        this.queryParam.streamId = this.cameras[0] && this.cameras[0].streamId
        if (this.queryParam.streamId) {
          if (this.source === 'eventVideo') {
            this.searchEventVideoList()
          } else {
            this.handleQuery()
          }
        }
      } else {
        const today = new Date()
        const nowTime = today.getTime()
        const beforMilliseconds = nowTime - 1000 * 3600 * 24 * 7
        console.log(
          'this.parseTime(beforMilliseconds)',
          this.parseDateTime(beforMilliseconds, 'yyyy-MM-ddTHH:mm:ss+08:00')
        )
        const ntStr = this.parseDateTime(nowTime, 'yyyy-MM-ddTHH:mm:ss+08:00')
        const btStr = this.parseDateTime(beforMilliseconds, 'yyyy-MM-ddTHH:mm:ss+08:00')
        this.dateRange = [btStr, ntStr]
      }
    },
    searchBtn(type) {
      this.curStreamId = this.vedioType === '1' ? this.queryParam.streamId : 'brtc'
      if (!type || !this.historyObj[this.curStreamId] || !this.historyObj[this.curStreamId].length) {
        if (!type) {
          this.resetParams(this.queryParam)
        }
        if (this.source === 'eventVideo') {
          this.searchEventVideoList()
        } else {
          this.handleQuery()
        }
      }
    },
    searchEventVideoList() {
      this.loading = true
      searchEventVideoList({ eventTime: this.dateRange[0], streamId: this.queryParam.streamId }).then((response) => {
        console.log('video history list={}', response)
        if (response.data.length > 0) {
          // this.historyList = response.data
          this.$set(this.historyObj, this.queryParam.streamId, response.data)
          const startTime = moment(response.data[0].startTime)
          const t = this.dateRange[1] - startTime
          const d = new Date(t)
          const m = d.getMinutes()
          const s = d.getSeconds()
          this.offsetTime = t / 1000
          this.eventStartTime = m + '分' + s + '秒'
        }
        this.loading = false
      })
    },
    handleReady(e, StreamId) {
      console.log('handleReady **********')
      this.$set(this.playBtnDisabled, StreamId, false)
      this.setPlayingTrue(StreamId)
    },
    setPlayingTrue(StreamId) {
      for (const item of this.historyObj[StreamId || this.queryParam.streamId]) {
        this.$set(item, 'playing', false)
        if (
          this.playingRowMap[StreamId || this.queryParam.streamId] &&
          this.playingRowMap[StreamId || this.queryParam.streamId][item.id]
        ) {
          this.$set(item, 'playing', true)
        }
      }
      console.log('setPlayingTrue **', this)
    },
    handleError(StreamId) {
      this.$set(this.playBtnDisabled, StreamId, false)
      for (const item of this.historyObj[StreamId]) {
        this.$set(item, 'playing', false)
      }
    },
    playVideo(record, index, streamId) {
      if (record.isFreeze) {
        this.checkVideoType(record, index)
        return
      }
      const nowStreamId = streamId || this.curStreamId
      let url = record.url
      console.log('playVideoindex', index)
      this.playingRowMap[nowStreamId] = {}
      this.playingRowMap[nowStreamId][record.id] = true
      const protocol = window.location.protocol
      for (const item of this.secondaryCameras) {
        if (item.streamId === nowStreamId) {
          this.$set(item, 'cameraVisible', true)
          break
        }
      }
      if (this.lastUrls[nowStreamId] !== record.url) {
        this.$set(this.playBtnDisabled, nowStreamId, true)
      }
      this.$set(this.lastUrls, nowStreamId, record.url)
      if (this.source === 'eventVideo') {
        const timeStr = moment(this.dateRange[0])
        const startStr = moment(record.startTime)
        const endStr = moment(record.endTime)
        if (index === 0) {
          this.seekTime[nowStreamId] = this.offsetTime
          this.progressMarkers[nowStreamId] = [{ offset: this.offsetTime, text: '推荐播放起始点' }]
          if (timeStr < endStr) {
            const offsetTime = (timeStr - startStr) / 1000
            this.progressMarkers[nowStreamId].push({ offset: offsetTime, text: '事件发生时间点' })
          }
        } else {
          this.seekTime[nowStreamId] = undefined
          this.progressMarkers[nowStreamId] = []
          if (timeStr >= startStr) {
            const offsetTime = (timeStr - startStr) / 1000
            this.progressMarkers[nowStreamId].push({ offset: offsetTime, text: '事件发生时间点' })
          }
        }
      }
      if (url) {
        if (protocol === 'https:' && url.indexOf('https:') < 0) {
          url = url.replace('http', 'https')
        }
        // this.videoUrlObj[this.queryParam.streamId] = url
        if (this.vedioType === '1') {
          this.$set(this.videoUrlObj, nowStreamId, url)
        } else {
          this.brtcUrl = url
        }
      }
    },
    checkVideoType(record, index) {
      const that = this
      checkFileType({ objectName: record.ossObject }).then((res) => {
        if (res.data) {
          this.$confirm({
            title: '该视频文件为归档文件，需解冻后才能播放，解冻时间大约需1分钟，请确认是否解冻？',
            zIndex: 99999,
            onOk() {
              return that.unfreeze(record, index)
            },
            onCancel() {}
          })
        } else {
          this.unfreezeItem(record)
          this.$set(record, 'isFreeze', false)
          this.playVideo(record, index)
        }
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      console.log('this.dateRange=={}', this.dateRange)
      this.queryParam.pageNum = 1
      this.$set(this.queryParam.pageObj, this.curStreamId, {
        pageNum: 1
      })
      this.loadHistoryList()
    },
    resetParams(obj) {
      this.queryParam = obj || {
        streamId: undefined,
        startTime: undefined,
        cameraType: undefined,
        endTime: undefined,
        vehicleInnerId: this.info.innerId,
        pageNum: 1,
        pageSize: 10,
        pageObj: {}
      }
      this.historyObj = {}
      this.historyList = []
      this.total = 0
      this.totalObj = {}
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetParams()
      this.dateRange = []
      this.setDefaultRanbgeTime()
      if (this.info.rtc) {
        this.getVideoHistory()
      }
      // this.handleQuery()
    },
    getFileType(url) {
  // 从 URL 中解析出文件的扩展名
  const baseName = url.split('?')[0]
  const extension = baseName.split('.').pop()

  // 根据文件的扩展名返回对应的 MIME 类型
  return extension || 'unknown'
},
    loadBrtcHistoryList() {
      videoBrtcHistoryQuery({
        endTime: this.queryParam.endTime,
        pageNum: this.queryParam.pageNum,
        pageSize: this.queryParam.pageSize,
        startTime: this.queryParam.startTime,
        vehicleId: this.info.vehicleId
      })
      .then(response => {
        const streamId = 'brtc'
        console.log('loadBrtcHistoryList===', response)
        if (!response.rows) {
              this.loading = false
              this.$set(this.historyObj, streamId, [])
              this.historyObj[streamId] = []
              // this.historyList = []
              return
            }
            this.$set(this.historyObj, streamId, response.rows)
        console.log('this.historyObj===', this.historyObj)
            for (const item of this.historyObj[streamId]) {
              item.startTime = item.startTs.replace(' ', 'T') + '+08:00'
              item.endTime = item.endTs.replace(' ', 'T') + '+08:00'
              item.url = item.fileUrl
              item.fileFormat = this.getFileType(item.fileUrl)
              const startTime = moment(item.startTime)
              const endTime = moment(item.endTime)
              const t = endTime - startTime
              const d = new Date(t)
              const h = Math.floor(t / (1000 * 60 * 60))
              const m = d.getMinutes()
              const s = d.getSeconds()
              this.$set(item, 'allTime', (h ? h + '时' : '') + m + '分' + s + '秒')
              // this.$set(item, 'isFreeze', this.checkTimeBeforeWeek(endTime))
              this.$set(item, 'isFreeze', false)
              // item.allTime = (h ? h + '时' : '') + m + '分' + s + '秒'
              // item.isFreeze = this.checkTimeBeforeWeek(startTime)
            }
            this.setPlayingTrue(streamId)
            this.totalObj[streamId] = response.total
            // this.total = response.totalCount
            this.loading = false
      })
    },
    loadHistoryList() {
      // const streamId = e.target.value
      console.log('loadHistoryList  steamId={}', this.queryParam.streamId)
      this.btnType = 'primary'
      if (this.vedioType === '1' && !this.queryParam.streamId) {
        this.$warningEx('请先选择要查询的摄像头！')
        return
      }
      if (this.dateRange.length !== 2) {
        this.$warningEx('请先选择要查询的时间范围！')
        return
      }
      this.queryParam.startTime = this.dateRange[0]
      this.queryParam.endTime = this.dateRange[1]
      this.loading = true
      if (this.vedioType === '2') {
        this.loadBrtcHistoryList()
        return
      }
      if (this.queryParam.streamId) {
        videoHistoryQuery(this.queryParam)
          .then((response) => {
            console.log('video history list={}', response)
            if (!response.records) {
              this.loading = false
              this.$set(this.historyObj, this.queryParam.streamId, [])
              this.historyObj[this.queryParam.streamId] = []
              // this.historyList = []
              return
            }
            // this.historyList = response.records
            this.$set(this.historyObj, this.queryParam.streamId, response.records)
            for (const item of this.historyObj[this.queryParam.streamId]) {
              const startTime = moment(item.startTime)
              const endTime = moment(item.endTime)
              const t = endTime - startTime
              const d = new Date(t)
              const h = Math.floor(t / (1000 * 60 * 60))
              const m = d.getMinutes()
              const s = d.getSeconds()
              this.$set(item, 'allTime', (h ? h + '时' : '') + m + '分' + s + '秒')
              this.$set(item, 'isFreeze', this.checkTimeBeforeWeek(endTime))
              // item.allTime = (h ? h + '时' : '') + m + '分' + s + '秒'
              // item.isFreeze = this.checkTimeBeforeWeek(startTime)
            }
            this.setPlayingTrue()
            this.totalObj[this.queryParam.streamId] = response.totalCount
            // this.total = response.totalCount
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    checkTimeBeforeWeek(time) {
      const d = new Date()
      const nt = d.getTime()
      const wt = 7 * 24 * 60 * 60 * 1000
      if (nt - time >= wt) {
        return true
      }
      return false
    },
    replaceHost(url) {
      return url.replace('http://camera-vehicle-revo.oss-cn-shenzhen.aliyuncs.com', 'https://vedio2.revolution.auto')
    },
    handleCloaseCameraHistory() {
      this.cameraHistoryVisible = false
      this.playBtnDisabled = {}
      this.lastUrls = {}
      this.setCameras(this.info, 'init')
      this.playingRowMap[this.curStreamId] = {}
      this.$emit('closeModalCameraHistory')
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.loadHistoryList()
    },
    changeSize(current, pageSize) {
      this.$set(this.queryParam.pageObj, this.curStreamId, {
        pageNum: current
      })
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.loadHistoryList()
    },
    rowClassName(record) {
      return record.playing ? 'playing' : 'no-playing'
    },
    unfreeze(record, index) {
      this.$set(this.unfreezingList, record.id, true)
      const keepFreezeStreamId = this.curStreamId
      unfreeze({ objectName: record.ossObject })
        .then((res) => {
          if (res.data.code === 200) {
            // 若轮询获取成功状态清除定时器
            if (this.freezeTimeOut[record.id]) {
              clearTimeout(this.freezeTimeOut[record.id])
              this.freezeTimeOut[record.id] = null
            }
            this.$set(this.unfreezingList, record.id, false)
            this.unfreezeItem(record, keepFreezeStreamId)
            this.$set(record, 'isFreeze', false)
            this.$message.success('解冻成功', 3)
            this.playVideo(record, index, keepFreezeStreamId)
          } else if (res.data.code === 1001) {
            // 解冻中时要进行轮询，更新状态
            this.freezeTimeOut[record.id] = setTimeout(() => {
              this.unfreeze(record, index)
            }, 1000)
          }
        })
        .catch((e) => {
          this.$set(this.unfreezingList, record.id, false)
        })
    },
    unfreezeItem(record, keepFreezeStreamId) {
      // 此处为改变页面展示，转为播放按钮显示， this.$set(record, 'isFreeze', false) 用于播放事件里判断是否解冻
      for (const item of this.historyObj[keepFreezeStreamId || this.curStreamId]) {
        if (item.id === record.id) {
          this.$set(item, 'isFreeze', false)
          // item.isFreeze = false
        }
      }
    },
    getVideoHistory() {
      this.queryParam.startTime = this.dateRange[0]
      this.queryParam.endTime = this.dateRange[1]
      console.log('getVideoHistory *******')
      getVideoHistory(this.queryParam).then((res) => {
        this.cameras = res.data.cameras || []
        if (!res.data.cameras) {
          this.queryParam.streamId = null
        }
      })
    },
    rangPickerChange(e) {
      console.log('rangPickerChange', e)
      if (this.info.rtc) {
        this.this.objectInfo && this.objectInfo.rtc()
      }
    },
    radioChange(e) {
      // 摄像头切换事件
      this.searchBtn('radio')
    },
    vedioTypeChange() {

    }
  }
}
</script>

<style lang="less">
.history-modal .vxe-modal--content {
  overflow-x: hidden !important;
}

.camera-history-modal {
  .history-modal {
    .vxe-modal--box {
      min-height: 380px;
      .vxe-modal--content {
        overflow-x: hidden;
      }
    }
  }
  .ant-card {
    height: auto;
    .ant-card-body {
      height: inherit;
      padding: 0px;
      .ant-badge-count,
      .ant-badge-dot,
      .ant-badge .ant-scroll-number-custom-component {
        transform: translate(0%, 0%);
      }
      .ant-card-grid {
        height: inherit;
      }
    }
  }
  .a-disabled {
    color: #888 !important;
    cursor: not-allowed;
  }
}
</style>

<style lang="less">
.video-table {
  .playing {
    background-color: @secondary-color;
  }
}
</style>
