var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "camera-history-modal" },
    [
      _c("vxe-modal", {
        staticClass: "history-modal",
        attrs: {
          width: "70%",
          height: "100%",
          title: _vm.modalTitle,
          "show-zoom": "",
          resize: "",
          "lock-view": false,
          mask: false,
          "esc-closable": true,
          position: _vm.position,
          transfer: "",
        },
        on: {
          close: _vm.handleCloaseCameraHistory,
          hide: _vm.handleCloaseCameraHistory,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "a-row",
                  { staticStyle: { height: "45%" } },
                  [
                    _c(
                      "a-col",
                      { staticStyle: { height: "100%" }, attrs: { span: 24 } },
                      [
                        _c("revo-video-player", {
                          attrs: {
                            url:
                              _vm.vedioType === "1"
                                ? _vm.videoUrlObj[_vm.mainCameras.streamId]
                                : _vm.brtcUrl,
                            "is-live": false,
                            progressMarkers:
                              _vm.progressMarkers[_vm.mainCameras.streamId],
                            seekTime:
                              _vm.source === "eventVideo" &&
                              _vm.seekTime[_vm.mainCameras.streamId],
                          },
                          on: {
                            handleReady: function ($event) {
                              return _vm.handleReady(
                                $event,
                                _vm.mainCameras.streamId
                              )
                            },
                            handleError: function ($event) {
                              return _vm.handleError(_vm.mainCameras.streamId)
                            },
                          },
                        }),
                        _vm.source === "eventVideo"
                          ? _c("p", [
                              _vm._v(
                                "播放查询出来的第一个视频，该视频将从事件发生的前20秒开始播放"
                              ),
                            ])
                          : _c("p", [_vm._v("(提供7天内的历史视频查询)")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { staticStyle: { height: "auto" } },
                  [
                    _c(
                      "a-col",
                      {
                        staticClass: "table-page-search-wrapper",
                        attrs: { span: 24 },
                      },
                      [
                        _c("a-divider"),
                        _c(
                          "a-form",
                          { attrs: { layout: "inline" } },
                          [
                            _c(
                              "a-row",
                              { attrs: { gutter: 48 } },
                              [
                                _vm.source !== "eventVideo"
                                  ? _c(
                                      "a-col",
                                      { attrs: { span: 16 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "类型",
                                              prop: "vedioType",
                                            },
                                          },
                                          [
                                            _c(
                                              "a-radio-group",
                                              {
                                                attrs: {
                                                  "button-style": "solid",
                                                },
                                                on: {
                                                  change: _vm.vedioTypeChange,
                                                },
                                                model: {
                                                  value: _vm.vedioType,
                                                  callback: function ($$v) {
                                                    _vm.vedioType = $$v
                                                  },
                                                  expression: "vedioType",
                                                },
                                              },
                                              [
                                                _c(
                                                  "a-radio-button",
                                                  {
                                                    key: 0,
                                                    attrs: { value: "1" },
                                                  },
                                                  [_vm._v(" 实时监控 ")]
                                                ),
                                                _c(
                                                  "a-radio-button",
                                                  {
                                                    key: 1,
                                                    attrs: { value: "2" },
                                                  },
                                                  [_vm._v(" 遥控 ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.vedioType === "1"
                                  ? _c(
                                      "a-col",
                                      {
                                        attrs: {
                                          span:
                                            _vm.source === "eventVideo"
                                              ? 16
                                              : 24,
                                        },
                                      },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "选择摄像头",
                                              prop: "code",
                                            },
                                          },
                                          [
                                            _c(
                                              "a-radio-group",
                                              {
                                                attrs: {
                                                  "button-style": "solid",
                                                },
                                                on: { change: _vm.radioChange },
                                                model: {
                                                  value:
                                                    _vm.queryParam.streamId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.queryParam,
                                                      "streamId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "queryParam.streamId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.cameras,
                                                function (c, index) {
                                                  return _c(
                                                    "a-radio-button",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        value: c.streamId,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(c.cameraName) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.source !== "eventVideo"
                                  ? _c(
                                      "a-col",
                                      { attrs: { span: 16 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          { attrs: { label: "时间范围" } },
                                          [
                                            _c("a-range-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                ranges: {
                                                  今天: [
                                                    _vm.moment(),
                                                    _vm.moment(),
                                                  ],
                                                  这个月: [
                                                    _vm.moment(),
                                                    _vm.moment().endOf("month"),
                                                  ],
                                                },
                                                "show-time": "",
                                                format: "YYYY-MM-DDTHH:mm:ss",
                                                valueFormat:
                                                  "YYYY-MM-DDTHH:mm:ssZ",
                                                "allow-clear": "",
                                              },
                                              on: {
                                                change: _vm.rangPickerChange,
                                              },
                                              model: {
                                                value: _vm.dateRange,
                                                callback: function ($$v) {
                                                  _vm.dateRange = $$v
                                                },
                                                expression: "dateRange",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("a-col", { attrs: { span: 8 } }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "table-page-search-submitButtons",
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          style:
                                            _vm.source !== "eventVideo"
                                              ? ""
                                              : "float: right",
                                          attrs: {
                                            type: "primary",
                                            id: "tableQueryBtn",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.searchBtn()
                                            },
                                          },
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "search" },
                                          }),
                                          _vm._v("查询 "),
                                        ],
                                        1
                                      ),
                                      _vm.source !== "eventVideo"
                                        ? _c(
                                            "a-button",
                                            {
                                              staticStyle: {
                                                "margin-left": "8px",
                                              },
                                              on: { click: _vm.resetQuery },
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: "redo" },
                                              }),
                                              _vm._v("重置 "),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "a-row",
                  { staticStyle: { height: "auto" } },
                  [
                    _c(
                      "a-col",
                      { attrs: { span: 24 } },
                      [
                        _c("a-table", {
                          staticClass: "video-table",
                          attrs: {
                            loading: _vm.loading,
                            size: _vm.tableSize,
                            columns: _vm.columns,
                            "data-source": _vm.historyObj[_vm.curStreamId],
                            pagination: false,
                            rowClassName: _vm.rowClassName,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "width-height",
                              fn: function (text, record) {
                                return _c("span", {}, [
                                  _vm._v(
                                    " " +
                                      _vm._s(record.width) +
                                      " * " +
                                      _vm._s(record.height) +
                                      " "
                                  ),
                                ])
                              },
                            },
                            {
                              key: "operation",
                              fn: function (text, record, index) {
                                return _c("span", {}, [
                                  _c(
                                    "a",
                                    {
                                      style:
                                        _vm.playBtnDisabled[_vm.curStreamId] ||
                                        _vm.unfreezingList[record.id]
                                          ? "color: #888 !important;cursor: not-allowed"
                                          : "",
                                      on: {
                                        click: function ($event) {
                                          !_vm.playBtnDisabled[
                                            _vm.curStreamId
                                          ] &&
                                            !_vm.unfreezingList[record.id] &&
                                            _vm.playVideo(record, index)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            record.isFreeze
                                              ? _vm.unfreezingList[record.id]
                                                ? "解冻中"
                                                : "解冻&播放"
                                              : record.playing
                                              ? "播放中"
                                              : "播放"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              },
                            },
                          ]),
                        }),
                        _vm.source !== "eventVideo"
                          ? _c("a-pagination", {
                              staticClass: "ant-table-pagination",
                              attrs: {
                                "show-size-changer": "",
                                "show-quick-jumper": "",
                                current: _vm.queryParam.pageObj[_vm.curStreamId]
                                  ? _vm.queryParam.pageObj[_vm.curStreamId]
                                      .pageNum
                                  : _vm.queryParam.pageNum,
                                total: _vm.totalObj[_vm.curStreamId],
                                "page-size": _vm.queryParam.pageSize,
                                showTotal: function (total) {
                                  return "共 " + total + " 条"
                                },
                              },
                              on: {
                                showSizeChange: _vm.onShowSizeChange,
                                change: _vm.changeSize,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.cameraHistoryVisible,
          callback: function ($$v) {
            _vm.cameraHistoryVisible = $$v
          },
          expression: "cameraHistoryVisible",
        },
      }),
      _vm._l(_vm.secondaryCameras, function (cam, index) {
        return _c("revo-video-modal", {
          key: index,
          attrs: {
            "is-live": false,
            progressMarkers: _vm.progressMarkers[cam.streamId || cam.iotId],
            seekTime:
              _vm.source === "eventVideo" &&
              _vm.seekTime[cam.streamId || cam.iotId],
            cameraInfo: cam,
            videoUrl: _vm.videoUrlObj[cam.streamId],
            cameraId: cam.id,
            position: _vm.setCameraHistoryPosition(index),
          },
          on: {
            closeModalCamera: function ($event) {
              return _vm.closeModalCameraChild(
                cam.id,
                cam.streamId || cam.iotId
              )
            },
            handleReady: function ($event) {
              return _vm.handleReady($event, cam.streamId || cam.iotId)
            },
            handleError: function ($event) {
              return _vm.handleError(cam.streamId || cam.iotId)
            },
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }